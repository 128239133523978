var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c(
            "vue-tabs",
            [
              _c("v-tab", { attrs: { title: "Thông tin chung" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body form-card" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Tên cuộc đua"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: _vm.errors.has("vi_title")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "Tên cuộc đua",
                                  "data-vv-name": "vi_title",
                                  "data-vv-as": "Tên cuộc đua",
                                },
                                model: {
                                  value: _vm.form.vi_title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vi_title", $$v)
                                  },
                                  expression: "form.vi_title",
                                },
                              }),
                              _vm.errors.has("vi_title")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("vi_title"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Tên cuộc đua (en)"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: _vm.errors.has("en_title")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "Tên cuộc đua (en)",
                                  "data-vv-name": "en_title",
                                  "data-vv-as": "Tên cuộc đua (en)",
                                },
                                model: {
                                  value: _vm.form.en_title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "en_title", $$v)
                                  },
                                  expression: "form.en_title",
                                },
                              }),
                              _vm.errors.has("en_title")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("en_title"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("SKU")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "SKU" },
                                model: {
                                  value: _vm.form.sku,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sku", $$v)
                                  },
                                  expression: "form.sku",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Đường dẫn"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  class: _vm.errors.has("slug")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    placeholder: "duong-dan",
                                    "data-vv-name": "slug",
                                    "data-vv-as": "Đường dẫn",
                                  },
                                  model: {
                                    value: _vm.form.slug,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "slug", $$v)
                                    },
                                    expression: "form.slug",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("https://irace.vn/races/"),
                                  ]),
                                ],
                                2
                              ),
                              _vm.errors.has("slug")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.errors.first("slug"))),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Địa điểm"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: _vm.errors.has("vi_address")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "Địa điểm",
                                  "data-vv-name": "vi_address",
                                  "data-vv-as": "Địa điểm",
                                },
                                model: {
                                  value: _vm.form.vi_address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vi_address", $$v)
                                  },
                                  expression: "form.vi_address",
                                },
                              }),
                              _vm.errors.has("vi_address")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("vi_address"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Địa điểm (en)"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: _vm.errors.has("en_address")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "Địa điểm (en)",
                                  "data-vv-name": "en_address",
                                  "data-vv-as": "Địa điểm (en)",
                                },
                                model: {
                                  value: _vm.form.en_address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "en_address", $$v)
                                  },
                                  expression: "form.en_address",
                                },
                              }),
                              _vm.errors.has("en_address")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("en_address"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Kiểu"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("my-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "full-width",
                                class: _vm.errors.has("type_id")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  attribute: _vm.raceTypes,
                                  placeholder: "Chọn kiểu...",
                                  "data-vv-name": "type_id",
                                  "data-vv-as": "Kiểu",
                                },
                                model: {
                                  value: _vm.form.type_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "type_id", $$v)
                                  },
                                  expression: "form.type_id",
                                },
                              }),
                              _vm.errors.has("type_id")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.errors.first("type_id"))),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm.form.type_id == 11
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c("label", [_vm._v("Bước chân/km")]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-10" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "Bước chân/km",
                                    },
                                    model: {
                                      value: _vm.form.step_to_km,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "step_to_km", $$v)
                                      },
                                      expression: "form.step_to_km",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Thể loại"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("my-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "full-width",
                                class: _vm.errors.has("category_id")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  attribute: _vm.raceCategories,
                                  placeholder: "Chọn thể loại...",
                                  "data-vv-name": "category_id",
                                  "data-vv-as": "Thể loại",
                                },
                                model: {
                                  value: _vm.form.category_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "category_id", $$v)
                                  },
                                  expression: "form.category_id",
                                },
                              }),
                              _vm.errors.has("category_id")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("category_id"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm.form.category_id == 4
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c("label", { staticClass: "required" }, [
                                  _vm._v("Số ngày"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-10" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "Số ngày",
                                    },
                                    model: {
                                      value: _vm.form.days,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "days", $$v)
                                      },
                                      expression: "form.days",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.form.category_id == 4
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c("label", [_vm._v("Race trước")]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-10" },
                                [
                                  _c("my-select", {
                                    staticClass: "full-width",
                                    attrs: {
                                      attribute: _vm.races,
                                      placeholder: "Chọn race...",
                                    },
                                    model: {
                                      value: _vm.form.required_completed_race,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "required_completed_race",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.required_completed_race",
                                    },
                                  }),
                                  _c(
                                    "i",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "red",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "* Sau khi hoàn thành race này thì mới đăng ký được"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.form.category_id == 3
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c("label", [_vm._v("Link đối tác")]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-10" },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Link đối tác" },
                                    model: {
                                      value: _vm.form.partner_link,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "partner_link", $$v)
                                      },
                                      expression: "form.partner_link",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("Chủ đề")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("my-select", {
                                staticClass: "full-width",
                                attrs: {
                                  attribute: _vm.raceThemes,
                                  placeholder: "Chọn nhiều chủ đề...",
                                },
                                model: {
                                  value: _vm.form.themes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "themes", $$v)
                                  },
                                  expression: "form.themes",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Đăng ký tối đa"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min_value:0",
                                    expression: "'required|min_value:0'",
                                  },
                                ],
                                class: _vm.errors.has("price")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  type: "number",
                                  placeholder: "Đăng ký tối đa",
                                  "data-vv-name": "limit",
                                  "data-vv-as": "Đăng ký tối đa",
                                },
                                model: {
                                  value: _vm.form.limit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "limit", $$v)
                                  },
                                  expression: "form.limit",
                                },
                              }),
                              _vm.errors.has("limit")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.errors.first("limit"))),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("Đăng ký ảo")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "Người chơi ảo",
                                },
                                model: {
                                  value: _vm.form.virtual_user,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "virtual_user", $$v)
                                  },
                                  expression: "form.virtual_user",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-4 sidebar-right" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body form-card" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [
                              _vm._v("Thời gian kết thúc khắc tên"),
                            ]),
                            _c("el-date-picker", {
                              staticClass: "full-width",
                              attrs: {
                                type: "datetime",
                                placeholder: "Chọn ngày giờ",
                              },
                              model: {
                                value: _vm.form.engraved_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "engraved_at", $$v)
                                },
                                expression: "form.engraved_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.form.category_id != 4
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "required" }, [
                                  _vm._v("Thời gian mở đăng ký"),
                                ]),
                                _c("el-date-picker", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "full-width",
                                  class: _vm.errors.has("start_register_at")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "Chọn ngày giờ",
                                    "data-vv-name": "start_register_at",
                                    "data-vv-as": "Thời gian mở đăng ký",
                                  },
                                  model: {
                                    value: _vm.form.start_register_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "start_register_at",
                                        $$v
                                      )
                                    },
                                    expression: "form.start_register_at",
                                  },
                                }),
                                _vm.errors.has("start_register_at")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("start_register_at")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.category_id != 4
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "required" }, [
                                  _vm._v("Thời gian đóng đăng ký"),
                                ]),
                                _c("el-date-picker", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "full-width",
                                  class: _vm.errors.has("register_at")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "Chọn ngày giờ",
                                    "data-vv-name": "register_at",
                                    "data-vv-as": "Thời gian kết thúc đăng ký",
                                  },
                                  model: {
                                    value: _vm.form.register_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "register_at", $$v)
                                    },
                                    expression: "form.register_at",
                                  },
                                }),
                                _vm.errors.has("register_at")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("register_at"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.category_id != 4
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "required" }, [
                                  _vm._v("Thời gian bắt đầu ghi nhận kết quả"),
                                ]),
                                _c("el-date-picker", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "full-width",
                                  class: _vm.errors.has("start_at")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "Chọn ngày giờ",
                                    "data-vv-name": "start_at",
                                    "data-vv-as":
                                      "Thời gian bắt đầu ghi nhận kết quả",
                                  },
                                  model: {
                                    value: _vm.form.start_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "start_at", $$v)
                                    },
                                    expression: "form.start_at",
                                  },
                                }),
                                _vm.errors.has("start_at")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("start_at"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _vm._v("Thời gian bắt đầu ghi nhận kết quả"),
                                ]),
                                _c("el-date-picker", {
                                  staticClass: "full-width",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "Chọn ngày giờ",
                                  },
                                  model: {
                                    value: _vm.form.start_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "start_at", $$v)
                                    },
                                    expression: "form.start_at",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm.form.category_id != 4
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "required" }, [
                                  _vm._v("Thời gian kết thúc ghi nhận kết quả"),
                                ]),
                                _c("el-date-picker", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|earlier",
                                      expression: "'required|earlier'",
                                    },
                                  ],
                                  staticClass: "full-width",
                                  class: _vm.errors.has("end_at")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "Chọn ngày giờ",
                                    "data-vv-name": "end_at",
                                    "data-vv-as":
                                      "Thời gian kết thúc ghi nhận kết quả",
                                  },
                                  model: {
                                    value: _vm.form.end_at,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "end_at", $$v)
                                    },
                                    expression: "form.end_at",
                                  },
                                }),
                                _vm.errors.has("end_at")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("end_at"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Mã tham gia")]),
                            _c("el-input", {
                              attrs: { placeholder: "Mã tham gia" },
                              model: {
                                value: _vm.form.join_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "join_code", $$v)
                                },
                                expression: "form.join_code",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Nổi bật")]),
                            _c("el-switch", {
                              staticClass: "pull-right",
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.hot,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hot", $$v)
                                },
                                expression: "form.hot",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Private")]),
                            _c("el-switch", {
                              staticClass: "pull-right",
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.unlisted,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "unlisted", $$v)
                                },
                                expression: "form.unlisted",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Cự ly" } }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body form-card" },
                        [
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-2" }, [
                              _c("label", [_vm._v("Không chọn cự ly")]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-10" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  model: {
                                    value: _vm.form.is_without_catalog,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "is_without_catalog",
                                        $$v
                                      )
                                    },
                                    expression: "form.is_without_catalog",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.form.is_without_catalog == 0
                            ? _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", { staticClass: "required" }, [
                                    _vm._v("Cự ly"),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-10" },
                                  [
                                    _c("my-select", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.disable",
                                          value: "required",
                                          expression: "'required'",
                                          modifiers: { disable: true },
                                        },
                                      ],
                                      staticClass: "full-width",
                                      class: _vm.errors.has("catalogs")
                                        ? "border-danger"
                                        : "",
                                      attrs: {
                                        attribute: _vm.raceCatalogs,
                                        placeholder: "Chọn nhiều cự ly...",
                                        "data-vv-name": "catalogs",
                                        "data-vv-as": "Cự ly",
                                      },
                                      model: {
                                        value: _vm.form.catalogs,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "catalogs", $$v)
                                        },
                                        expression: "form.catalogs",
                                      },
                                    }),
                                    _vm.errors.has("catalogs")
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first("catalogs")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.form.catalogs && _vm.form.catalogs.length
                            ? _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", [
                                    _vm._v("Giới hạn suất đăng ký"),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-10" },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-value": 1,
                                        "inactive-value": 0,
                                      },
                                      model: {
                                        value: _vm.form.is_limit_slot_catalog,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "is_limit_slot_catalog",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.is_limit_slot_catalog",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.list_catalogs, function (item, index) {
                            return _vm.list_catalogs && _vm.list_catalogs.length
                              ? _c(
                                  "div",
                                  { key: index, staticClass: "form-group row" },
                                  [
                                    _c("div", { staticClass: "col-2" }, [
                                      _c("label", [_vm._v(_vm._s(item.title))]),
                                    ]),
                                    _c("div", { staticClass: "col-10" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _vm.form.is_limit_slot_catalog
                                          ? _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      placeholder:
                                                        "Suất đăng ký",
                                                    },
                                                    model: {
                                                      value: item.limit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "limit",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.limit",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("suất")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.is_limit_slot_catalog
                                          ? _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      type: "number",
                                                      disabled: true,
                                                      placeholder: "Đã đăng ký",
                                                    },
                                                    model: {
                                                      value: item.sold,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "sold",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.sold",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("suất")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "col-4" },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "Cut off time",
                                                },
                                                model: {
                                                  value: item.cut_off_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "cut_off_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.cut_off_time",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("phút")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e()
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Combo" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Combo")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_combo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_combo", $$v)
                              },
                              expression: "form.is_combo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.form.is_combo
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("Bắt buộc mua tất cả race")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value:
                                    _vm.form.is_required_buy_all_race_comobo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "is_required_buy_all_race_comobo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.is_required_buy_all_race_comobo",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.is_combo,
                            expression: "form.is_combo",
                          },
                        ],
                        staticClass: "form-group row",
                      },
                      [
                        _c("div", { staticClass: "col-2" }, [
                          _c("label", [_vm._v("Chọn race")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.races,
                                multiple: true,
                                placeholder: "Chọn race...",
                              },
                              model: {
                                value: _vm.form.race_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "race_ids", $$v)
                                },
                                expression: "form.race_ids",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.is_combo &&
                              _vm.form.race_ids &&
                              _vm.form.race_ids.length > 1,
                            expression:
                              "form.is_combo && form.race_ids && form.race_ids.length > 1",
                          },
                        ],
                        staticClass: "form-group row",
                      },
                      [
                        _c("div", { staticClass: "col-2" }, [
                          _c("label", [_vm._v("Giảm giá")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          _vm._l(_vm.form.race_ids, function (item, index) {
                            return _c("div", [
                              index < _vm.form.race_ids.length - 1
                                ? _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-4" }, [
                                      _vm._v(
                                        "Giảm giá khi mua " +
                                          _vm._s(index + 2) +
                                          " race (%):"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-8" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "5%",
                                            type: "number",
                                          },
                                          model: {
                                            value:
                                              _vm.combo_discounts[index + 2],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.combo_discounts,
                                                index + 2,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "combo_discounts[index + 2]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Giá" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Miễn phí vận chuyển")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_free_ship,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_free_ship", $$v)
                              },
                              expression: "form.is_free_ship",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.form.is_free_ship == 0
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", { staticClass: "required" }, [
                              _vm._v("Phí vận chuyển"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min_value:1",
                                    expression: "'required|min_value:1'",
                                  },
                                ],
                                class: _vm.errors.has("shipping_fee")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "Phí vận chuyển",
                                  type: "number",
                                  "data-vv-name": "shipping_fee",
                                  "data-vv-as": "Phí vận chuyển",
                                },
                                model: {
                                  value: _vm.form.shipping_fee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shipping_fee", $$v)
                                  },
                                  expression: "form.shipping_fee",
                                },
                              }),
                              _vm.errors.has("shipping_fee")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("shipping_fee"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Miễn phí")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_free,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_free", $$v)
                              },
                              expression: "form.is_free",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.form.is_free == 0
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("Kiểu giá")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.prices.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prices, "type", $$v)
                                    },
                                    expression: "prices.type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "one" } },
                                    [_vm._v("Một giá")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "many" } },
                                    [_vm._v("Nhiều giá")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "catalog" } },
                                    [_vm._v("Giá theo cự ly")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.is_free == 0 && _vm.prices.type == "one",
                        expression: "form.is_free == 0 && prices.type == 'one'",
                      },
                    ],
                    staticClass: "card",
                  },
                  [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "catalog-title" }, [
                          _vm._v("Một giá"),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v("Giá bán"),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá bán",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.one.standard,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.one,
                                            "standard",
                                            $$v
                                          )
                                        },
                                        expression: "prices.one.standard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v("Giá so sánh"),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá so sánh",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.one.compare,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.one,
                                            "compare",
                                            $$v
                                          )
                                        },
                                        expression: "prices.one.compare",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.is_free == 0 && _vm.prices.type == "many",
                        expression:
                          "form.is_free == 0 && prices.type == 'many'",
                      },
                    ],
                    staticClass: "card",
                  },
                  [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "catalog-title" }, [
                          _vm._v("Nhiều giá"),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Super early bird"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá Super early bird",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many
                                            .start_at_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "start_at_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.start_at_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many
                                            .end_at_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "end_at_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.end_at_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many
                                            .inventory_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "inventory_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.inventory_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many.sold_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "sold_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.sold_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Early bird"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá Early bird",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "early_bird",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many.start_at_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "start_at_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.start_at_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many.end_at_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "end_at_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.end_at_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many.inventory_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "inventory_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.inventory_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.sold_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "sold_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.sold_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Regular"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá Regular",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "regular",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value: _vm.prices.many.start_at_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "start_at_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.start_at_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value: _vm.prices.many.end_at_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "end_at_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.end_at_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.many.inventory_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "inventory_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.inventory_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.sold_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "sold_regular",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.sold_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [_vm._v("Late")]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Giá Late",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.late,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.prices.many, "late", $$v)
                                        },
                                        expression: "prices.many.late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value: _vm.prices.many.start_at_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "start_at_late",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.start_at_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value: _vm.prices.many.end_at_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "end_at_late",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.end_at_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.inventory_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "inventory_late",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.many.inventory_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.many.sold_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.many,
                                            "sold_late",
                                            $$v
                                          )
                                        },
                                        expression: "prices.many.sold_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.is_free == 0 && _vm.prices.type == "catalog",
                        expression:
                          "form.is_free == 0 && prices.type == 'catalog'",
                      },
                    ],
                    staticClass: "card",
                  },
                  [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("b", { staticClass: "catalog-title" }, [
                          _vm._v("Giá theo cự ly"),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Chọn cự ly"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.raceCatalogs,
                                placeholder: "Chọn nhiều cự ly...",
                              },
                              model: {
                                value: _vm.form.catalogs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "catalogs", $$v)
                                },
                                expression: "form.catalogs",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Super Early Bird"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .start_at_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "start_at_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.start_at_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .end_at_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "end_at_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.end_at_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .inventory_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "inventory_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.inventory_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .sold_super_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "sold_super_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.sold_super_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Early Bird"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .start_at_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "start_at_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.start_at_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.end_at_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "end_at_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.end_at_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog
                                            .inventory_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "inventory_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.inventory_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.sold_early_bird,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "sold_early_bird",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.sold_early_bird",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v("Regular"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.start_at_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "start_at_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.start_at_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.end_at_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "end_at_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.end_at_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.inventory_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "inventory_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.inventory_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.catalog.sold_regular,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "sold_regular",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.sold_regular",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col-2" }, [_vm._v("Late")]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày bắt đầu",
                                      },
                                      model: {
                                        value: _vm.prices.catalog.start_at_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "start_at_late",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.start_at_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "full-width",
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "Chọn ngày kết thúc",
                                      },
                                      model: {
                                        value: _vm.prices.catalog.end_at_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "end_at_late",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.end_at_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Số suất đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value:
                                          _vm.prices.catalog.inventory_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "inventory_late",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "prices.catalog.inventory_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "Đã đăng ký",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.prices.catalog.sold_late,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prices.catalog,
                                            "sold_late",
                                            $$v
                                          )
                                        },
                                        expression: "prices.catalog.sold_late",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("hr"),
                      _vm.catalogSelectedPrice &&
                      _vm.catalogSelectedPrice.length
                        ? _c(
                            "div",
                            { staticClass: "list-price-catalog" },
                            _vm._l(_vm.catalogSelectedPrice, function (item) {
                              return _c(
                                "div",
                                { staticClass: "form-group row" },
                                [
                                  _c("div", { staticClass: "col-2" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-10" },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "Super Early Bird",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: item.super_early_bird,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "super_early_bird",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.super_early_bird",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "Early Bird",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: item.early_bird,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "early_bird",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.early_bird",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "Regular",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: item.regular,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "regular",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.regular",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "Late",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: item.late,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "late", $$v)
                                                  },
                                                  expression: "item.late",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
              _c("v-tab", { attrs: { title: "Hình ảnh" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Logo")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-image", {
                            model: {
                              value: _vm.form.logo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "logo", $$v)
                              },
                              expression: "form.logo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Ảnh cover")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-image", {
                            model: {
                              value: _vm.form.image,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "image", $$v)
                              },
                              expression: "form.image",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Huy chương (Medal)")]),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.window.SERVER_BASE_URL +
                                "/images/medal-01.png",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Tải template 01")]
                        ),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.window.SERVER_BASE_URL +
                                "/images/medal-02.png",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Tải template 02")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-10" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("my-image", {
                                model: {
                                  value: _vm.form.medal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "medal", $$v)
                                  },
                                  expression: "form.medal",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-6" }, [
                            _c("canvas", {
                              ref: "canvas-medal-deactive",
                              staticStyle: { "max-width": "300px" },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Số BIB")]),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.window.SERVER_BASE_URL +
                                "/images/bib-placeholder.jpg",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Tải template")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-10" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("my-image", {
                                model: {
                                  value: _vm.form.bib,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bib", $$v)
                                  },
                                  expression: "form.bib",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5 mid-text" }, [
                                _vm._v("Số BIB"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.color_options.bib_number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.color_options,
                                          "bib_number",
                                          $$v
                                        )
                                      },
                                      expression: "color_options.bib_number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5 mid-text" }, [
                                _vm._v("Tên + cự ly"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.color_options.bib_text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.color_options,
                                          "bib_text",
                                          $$v
                                        )
                                      },
                                      expression: "color_options.bib_text",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Giấy chứng nhận")]),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.window.SERVER_BASE_URL +
                                "/images/cert-placeholder.jpg",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Tải template")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-10" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("my-image", {
                                model: {
                                  value: _vm.form.certificate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "certificate", $$v)
                                  },
                                  expression: "form.certificate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5 mid-text" }, [
                                _vm._v("Tên người dùng"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.color_options.cert_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.color_options,
                                          "cert_name",
                                          $$v
                                        )
                                      },
                                      expression: "color_options.cert_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5 mid-text" }, [
                                _vm._v("Kết quả"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value: _vm.color_options.cert_result,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.color_options,
                                          "cert_result",
                                          $$v
                                        )
                                      },
                                      expression: "color_options.cert_result",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Về cuộc đua" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Mô tả ngắn")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_description,
                                callback: function ($$v) {
                                  _vm.active_tab_description = $$v
                                },
                                expression: "active_tab_description",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Mô tả ngắn",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.form.description_vi,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "description_vi",
                                          $$v
                                        )
                                      },
                                      expression: "form.description_vi",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Mô tả ngắn",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.form.description_en,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "description_en",
                                          $$v
                                        )
                                      },
                                      expression: "form.description_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Giới thiệu")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_introduce,
                                callback: function ($$v) {
                                  _vm.active_tab_introduce = $$v
                                },
                                expression: "active_tab_introduce",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.vi_introduce,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "vi_introduce", $$v)
                                      },
                                      expression: "form.vi_introduce",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.en_introduce,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "en_introduce", $$v)
                                      },
                                      expression: "form.en_introduce",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Giải thưởng")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_award,
                                callback: function ($$v) {
                                  _vm.active_tab_award = $$v
                                },
                                expression: "active_tab_award",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.vi_award,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "vi_award", $$v)
                                      },
                                      expression: "form.vi_award",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.en_award,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "en_award", $$v)
                                      },
                                      expression: "form.en_award",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "SEO" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Tiêu đề")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_seo_title,
                                callback: function ($$v) {
                                  _vm.active_tab_seo_title = $$v
                                },
                                expression: "active_tab_seo_title",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Tiêu đề", rows: 3 },
                                    model: {
                                      value: _vm.form.vi_title_seo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "vi_title_seo", $$v)
                                      },
                                      expression: "form.vi_title_seo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Tiêu đề", rows: 3 },
                                    model: {
                                      value: _vm.form.en_title_seo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "en_title_seo", $$v)
                                      },
                                      expression: "form.en_title_seo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Mô tả")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_seo_description,
                                callback: function ($$v) {
                                  _vm.active_tab_seo_description = $$v
                                },
                                expression: "active_tab_seo_description",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Mô tả",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.form.vi_description_seo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "vi_description_seo",
                                          $$v
                                        )
                                      },
                                      expression: "form.vi_description_seo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Mô tả",
                                      rows: 3,
                                    },
                                    model: {
                                      value: _vm.form.en_description_seo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "en_description_seo",
                                          $$v
                                        )
                                      },
                                      expression: "form.en_description_seo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Quỹ từ thiện" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Quỹ từ thiện")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.charities,
                              placeholder: "Chọn quỹ từ thiện...",
                            },
                            model: {
                              value: _vm.form.charity_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "charity_id", $$v)
                              },
                              expression: "form.charity_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Số tiền đóng góp")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-input", {
                            attrs: { type: "number", placeholder: "0" },
                            model: {
                              value: _vm.form.charity_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "charity_money", $$v)
                              },
                              expression: "form.charity_money",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Tổng tiền đóng góp")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-input", {
                            attrs: { type: "number", placeholder: "0" },
                            model: {
                              value: _vm.form.total_donate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total_donate", $$v)
                              },
                              expression: "form.total_donate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("KM/VND")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-input", {
                            attrs: { type: "number", placeholder: "0" },
                            model: {
                              value: _vm.form.km_to_vnd,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "km_to_vnd", $$v)
                              },
                              expression: "form.km_to_vnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Quy định" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Hoàn thành trong một lần chạy")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_one_lap,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_one_lap", $$v)
                              },
                              expression: "form.is_one_lap",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Cut off time")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "number",
                                placeholder: "Nhập số phút",
                              },
                              model: {
                                value: _vm.form.cut_off_time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cut_off_time", $$v)
                                },
                                expression: "form.cut_off_time",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("phút"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Quy định tính kết quả")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.raceRules,
                              multiple: true,
                              placeholder: "Quy định tính kết quả",
                            },
                            model: {
                              value: _vm.list_race_rules,
                              callback: function ($$v) {
                                _vm.list_race_rules = $$v
                              },
                              expression: "list_race_rules",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      _vm._l(_vm.list_race_rules, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "form-group row" },
                          [
                            _c("div", { staticClass: "col-2" }, [
                              _vm._v(_vm._s(_vm.hash_rules[item])),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-10" },
                              [
                                _vm.list_rules_checkbox.includes(item)
                                  ? _c("el-switch", {
                                      attrs: {
                                        "active-value": 1,
                                        "inactive-value": 0,
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    })
                                  : item === "TIME_SLOT"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 3,
                                        placeholder:
                                          "08:00-13:00 (Mỗi khoảng thời gian 1 dòng)",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    })
                                  : item === "DATE_SLOT"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 3,
                                        placeholder:
                                          "2020-10-20 (Mỗi ngày 1 dòng)",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    })
                                  : item === "MIN_DISTANCE_DATE"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 3,
                                        placeholder:
                                          "2020-10-20#####13 (Mỗi ngày 1 dòng)",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    })
                                  : item ===
                                    "DATE_HAVE_ONE_ACTIVITY_WITH_MIN_DISTANCE"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 3,
                                        placeholder:
                                          "2020-10-20#####13 (Mỗi ngày 1 dòng)",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    })
                                  : _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.hash_rules[item],
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.list_active_rules[item],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.list_active_rules,
                                            item,
                                            $$v
                                          )
                                        },
                                        expression: "list_active_rules[item]",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Quy định")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.rules,
                              multiple: true,
                              placeholder: "Chọn quy định...",
                            },
                            model: {
                              value: _vm.form.rules,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rules", $$v)
                              },
                              expression: "form.rules",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Quy định")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "border-card" },
                              model: {
                                value: _vm.active_tab_rule,
                                callback: function ($$v) {
                                  _vm.active_tab_rule = $$v
                                },
                                expression: "active_tab_rule",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng việt", name: "first" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.rule_vi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "rule_vi", $$v)
                                      },
                                      expression: "form.rule_vi",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Tiếng Anh", name: "second" },
                                },
                                [
                                  _c("my-editor", {
                                    attrs: {
                                      apiKey:
                                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                                    },
                                    model: {
                                      value: _vm.form.rule_en,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "rule_en", $$v)
                                      },
                                      expression: "form.rule_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Banner quảng cáo" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _vm.list_banner_ads && _vm.list_banner_ads.length
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          _vm._l(_vm.list_banner_ads, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "row form-group item-banner-ads",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("my-image", {
                                      model: {
                                        value: item.image,
                                        callback: function ($$v) {
                                          _vm.$set(item, "image", $$v)
                                        },
                                        expression: "item.image",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-8 right" },
                                  [
                                    _c("el-input", {
                                      staticClass: "form-group",
                                      attrs: { placeholder: "Tiêu đề" },
                                      model: {
                                        value: item.title,
                                        callback: function ($$v) {
                                          _vm.$set(item, "title", $$v)
                                        },
                                        expression: "item.title",
                                      },
                                    }),
                                    _c("el-input", {
                                      attrs: { placeholder: "Link" },
                                      model: {
                                        value: item.link,
                                        callback: function ($$v) {
                                          _vm.$set(item, "link", $$v)
                                        },
                                        expression: "item.link",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "btn-add-banner-adds" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.addBannerAds },
                          },
                          [_vm._v("Thêm banner (380 x 150)")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Facebook" } }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body form-card" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-2" }, [
                            _c("label", [_vm._v("Facebook")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.fb_post,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fb_post", $$v)
                                  },
                                  expression: "form.fb_post",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm.form.fb_post
                          ? _c("div", [
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", [_vm._v("Chọn loại")]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-10" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "full-width",
                                        attrs: {
                                          placeholder: "Chọn loại",
                                          clearable: "",
                                          multiple: "",
                                        },
                                        model: {
                                          value: _vm.fb_info.fb_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fb_info,
                                              "fb_type",
                                              $$v
                                            )
                                          },
                                          expression: "fb_info.fb_type",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "Photo",
                                            value: "photo",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "Video",
                                            value: "video",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", [_vm._v("Hashtag")]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-10" },
                                  [
                                    _vm._l(
                                      _vm.fb_info.hashtag,
                                      function (tag, index) {
                                        return _c(
                                          "el-tag",
                                          {
                                            key: index,
                                            attrs: {
                                              closable: "",
                                              "disable-transitions": false,
                                              type: "info",
                                            },
                                            on: {
                                              close: function ($event) {
                                                return _vm.removeHashtag(tag)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(tag))]
                                        )
                                      }
                                    ),
                                    _vm.input_visible_hashtag
                                      ? _c("el-input", {
                                          ref: "saveInputHashtag",
                                          staticClass: "input-new-tag",
                                          attrs: { size: "mini" },
                                          on: {
                                            blur: _vm.handleInputConfirmHashtag,
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.handleInputConfirmHashtag.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.input_new_hashtag,
                                            callback: function ($$v) {
                                              _vm.input_new_hashtag = $$v
                                            },
                                            expression: "input_new_hashtag",
                                          },
                                        })
                                      : _c(
                                          "el-button",
                                          {
                                            staticClass: "button-new-tag",
                                            attrs: { size: "small" },
                                            on: { click: _vm.showInputHashtag },
                                          },
                                          [_vm._v("+ New Tag")]
                                        ),
                                  ],
                                  2
                                ),
                              ]),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", [_vm._v("Tính điểm (total)")]),
                                ]),
                                _c("div", { staticClass: "col-10" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-2 pd" }, [
                                      _vm._v("1 Share bằng"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        staticStyle: { "padding-left": "0" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.fb_info.share_point,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fb_info,
                                                "share_point",
                                                $$v
                                              )
                                            },
                                            expression: "fb_info.share_point",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-3 pd" }, [
                                      _vm._v("điểm"),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-2 pd" }, [
                                      _vm._v("1 Comment bằng"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        staticStyle: { "padding-left": "0" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.fb_info.comment_point,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fb_info,
                                                "comment_point",
                                                $$v
                                              )
                                            },
                                            expression: "fb_info.comment_point",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-3 pd" }, [
                                      _vm._v("điểm"),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-2 pd" }, [
                                      _vm._v("1 Reaction bằng"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        staticStyle: { "padding-left": "0" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.fb_info.reaction_point,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fb_info,
                                                "reaction_point",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "fb_info.reaction_point",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-3 pd" }, [
                                      _vm._v("điểm"),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-2 pd" }, [
                                      _vm._v("1 View bằng"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        staticStyle: { "padding-left": "0" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.fb_info.view_point,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fb_info,
                                                "view_point",
                                                $$v
                                              )
                                            },
                                            expression: "fb_info.view_point",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-3 pd" }, [
                                      _vm._v("điểm"),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-2 pd" }, [
                                      _vm._v(
                                        "Hệ số reach\n                          "
                                      ),
                                      _c("div", [
                                        _c("small", [
                                          _vm._v("(Reach = total x hệ số)"),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        staticStyle: { "padding-left": "0" },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: _vm.fb_info.reach_point,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fb_info,
                                                "reach_point",
                                                $$v
                                              )
                                            },
                                            expression: "fb_info.reach_point",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Tiện ích" } }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body form-card" },
                        [
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-2" }, [
                              _c("label", [_vm._v("Tiện ích")]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-10" },
                              [
                                _c("my-select", {
                                  staticClass: "full-width",
                                  attrs: {
                                    attribute: _vm.addons,
                                    multiple: true,
                                    placeholder: "Chọn tiện ích",
                                  },
                                  model: {
                                    value: _vm.form.addons,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "addons", $$v)
                                    },
                                    expression: "form.addons",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(_vm.list_addons, function (item, index) {
                            return _vm.list_addons && _vm.list_addons.length
                              ? _c(
                                  "div",
                                  { key: index, staticClass: "form-group row" },
                                  [
                                    _c("div", { staticClass: "col-2" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.getAddonName(item.id))
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-10" },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-text": "Bắt buộc",
                                          },
                                          model: {
                                            value: item.required,
                                            callback: function ($$v) {
                                              _vm.$set(item, "required", $$v)
                                            },
                                            expression: "item.required",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Thông tin khác" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Địa điểm nhận huy chương")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.locations,
                              multiple: true,
                              placeholder: "Chọn địa điểm...",
                            },
                            model: {
                              value: _vm.form.locations,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "locations", $$v)
                              },
                              expression: "form.locations",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Sự kiện của")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.objects,
                              placeholder: "Chọn đối tượng",
                            },
                            model: {
                              value: _vm.form.object,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "object", $$v)
                              },
                              expression: "form.object",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [
                          _vm._v("Gửi email nhắc users khi giải còn X ngày"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "Nhập số ngày" },
                              model: {
                                value: _vm.form.remind_send_email_day,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "remind_send_email_day",
                                    $$v
                                  )
                                },
                                expression: "form.remind_send_email_day",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("ngày"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Áp dụng iVip")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "full-width",
                              attrs: {
                                placeholder: "Chọn đối tượng áp dụng",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.apply_vip_object,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "apply_vip_object", $$v)
                                },
                                expression: "form.apply_vip_object",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Không áp dụng", value: "" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Sự kiện", value: "race" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Tiện ích", value: "addon" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Tất cả", value: "all" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Huy chương thật")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_have_medal,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_have_medal", $$v)
                              },
                              expression: "form.is_have_medal",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Huy chương điện tử")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_have_medal_online,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_have_medal_online", $$v)
                              },
                              expression: "form.is_have_medal_online",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Giấy chứng nhận")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_have_certificate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_have_certificate", $$v)
                              },
                              expression: "form.is_have_certificate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Hiển thị số BIB")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_show_bib,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_show_bib", $$v)
                              },
                              expression: "form.is_show_bib",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Áp dụng Coupon")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_apply_coupon,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_apply_coupon", $$v)
                              },
                              expression: "form.is_apply_coupon",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Chia CLB theo bảng")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_show_group_club,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_show_group_club", $$v)
                              },
                              expression: "form.is_show_group_club",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.is_show_group_club,
                            expression: "form.is_show_group_club",
                          },
                        ],
                        staticClass: "form-group row",
                      },
                      [
                        _c("div", { staticClass: "col-2" }, [
                          _c("label", [_vm._v("Chọn danh sách bảng")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.groups,
                                multiple: true,
                                placeholder: "Chọn danh sách bảng",
                              },
                              model: {
                                value: _vm.form.group_clubs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "group_clubs", $$v)
                                },
                                expression: "form.group_clubs",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Bắt buộc nhập CMND")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.metafield.required_identity_card,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.metafield,
                                  "required_identity_card",
                                  $$v
                                )
                              },
                              expression: "metafield.required_identity_card",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _c("label", [_vm._v("Bắt buộc chọn CLB")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.metafield.required_club,
                              callback: function ($$v) {
                                _vm.$set(_vm.metafield, "required_club", $$v)
                              },
                              expression: "metafield.required_club",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-tab", { attrs: { title: "Thông tin thêm" } }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _vm.metafield.custom_field &&
                    _vm.metafield.custom_field.length
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          _vm._l(
                            _vm.metafield.custom_field,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "row form-group item-banner-ads",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-5" },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "form-group",
                                          attrs: { placeholder: "Tiêu đề" },
                                          model: {
                                            value: item.title,
                                            callback: function ($$v) {
                                              _vm.$set(item, "title", $$v)
                                            },
                                            expression: "item.title",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("Tiêu đề"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-4" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "full-width",
                                          attrs: {
                                            placeholder: "Chọn loại input",
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.input_type,
                                            callback: function ($$v) {
                                              _vm.$set(item, "input_type", $$v)
                                            },
                                            expression: "item.input_type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Input",
                                              value: "input",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "Textarea",
                                              value: "textarea",
                                              disabled: "",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "Editor",
                                              value: "editor",
                                              disabled: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-2",
                                      staticStyle: { "padding-top": "9px" },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": 1,
                                          "inactive-value": 0,
                                          "active-text": "Bắt buộc",
                                        },
                                        model: {
                                          value: item.required,
                                          callback: function ($$v) {
                                            _vm.$set(item, "required", $$v)
                                          },
                                          expression: "item.required",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-1" },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "danger",
                                          icon: "el-icon-delete",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCustomField(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "btn-add-banner-adds" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.addCustomField },
                          },
                          [_vm._v("Thêm")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "container-fluid" }, [
            _c(
              "div",
              { staticClass: "page-action" },
              [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("Quay lại"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.save } },
                  [_vm._v("Cập nhật")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function ($event) {
            _vm.loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }